import React from 'react';
import { MainLayout } from '../../layouts'
import {Footer, HeroSection, LineDotContainer, Paragraph, Title} from "../../components";


import  styles from './styles.module.css'

export const QualityPolicy = () => {
    return (
        <MainLayout>
            <HeroSection className={styles.customHero }>
                <Title colour="white" weight="bold" size="large">
                    Quality Policy
                </Title>
            </HeroSection>
            <div className={ styles.innerContent}>


                <LineDotContainer >
                    <Title>Calibrate Consulting Quality &amp; Information Security Policy: Committed to Quality and Excellence – #theCalibrateway</Title>

                    <Paragraph>Calibrate Consulting provides its clients with specialist consultancy services designed to help them digitally connect and transform their businesses. We solve complex business problems and advise each client on their unique digital transformation journey. We support our clients to design the most appropriate tailored solutions that help their businesses to thrive. We operate across industries and business functions and provide end to end services including management consulting, systems integration, on-premise, cloud and hybrid managed services and resourcing.</Paragraph>
                    <Paragraph>Since February 2018 to guide our firm, Calibrate has formally adopted and been driven by four key business objectives focused on our clients (#grow), our people (#teamcalibrate), our culture (#behuman) and continuous improvement (#simplify).</Paragraph>
                    <Paragraph>Quality starts with our people. For over a decade, Calibrate has been delivering lasting results to clients through our practitioner-led teams. We are a team of over 50 colleagues who are problem-solvers and experts in their fields, supported by an extended Calibrate Talent network. We assess technical and non-technical expertise through a rigorous selection and assessment process that incorporates in-house technical assessments along with structured interviews aligned to our bespoke competency model. We focus on expertise, aptitude and a strong desire to work in ‘The Calibrate Way’.</Paragraph>
                    <Paragraph>Underpinning our delivery capability and the trust between our clients and our people is Information security. Including client confidentiality and safeguarding personal data, we aim to build Information Security into all our activities. Information security starts with our people. We set policies to support the key principles of confidentiality, integrity and availability, we provide education and information to encourage information security as a natural part of everyday activity, and provide systems to #simplify the requirement for vigilance and adherence to standards. We control access to data and take steps to monitor activity and report issues and risks.</Paragraph>
                    <Paragraph>Calibrate Consulting is committed to providing clients with an exceptionally high quality service and delivery framework to meet their requirements on time and on budget, with high levels of client satisfaction. This naturally includes a high level of quality and information security.</Paragraph>
                    <Paragraph>We listen to our clients to understand their needs, bringing together our experts and knowledge to consistently deliver innovative and sustainable solutions. Applying technical expertise, industry knowledge and problem-solving skills in a collaborative way are fundamental to ‘The Calibrate Way’ of operating.</Paragraph>
                    <Paragraph>Calibrate Consulting operates a Quality Management System which it continuously reviews for improvement. The Operating Committee and every member of #teamcalibrate are jointly responsible for Quality and Information Security. Our business policy is to only work with suppliers and subcontractors who share our commitment to quality and support our aims.</Paragraph>
                    <Paragraph>Objectives<br />Calibrate Consulting is committed to exceeding our clients’ expectations by the use of Quality Procedures which will meet or exceed the requirements of ISO 9001.</Paragraph>
                    <Paragraph>Calibrate Consulting is committed to exceeding our clients’ expectations with regards to information security and will meet or exceed the requirements of ISO 27001.</Paragraph>
                    <Paragraph>To deliver secure, reliable cloud services for users (and other interested parties) and the means of providing assurance the platforms are fit for their purpose of sharing and working with sensitive information.</Paragraph>
                    <Paragraph>To ensure that all employees, contractors and third party users are aware of information security threats and concerns, their responsibilities and liabilities, and are equipped to support the organisational security policy in the course of their normal work, and to reduce the risk of human error.</Paragraph>
                    <Paragraph>To provide visibility to management that our systems are working properly and that access to information is appropriately controlled, including regular assurance that information assets are properly protected from internal and external threats.</Paragraph>
                    <Paragraph>To enable us to fulfil and be seen to fulfil our data safeguarding obligations to our clients, employees, contractors and other interested parties.</Paragraph>
                    <Title size="small">Scope of the Policy</Title>
                    <Paragraph>The scope of this policy relates to use of digital systems managed by the company, headquartered in The Spice Building, 8 Devonshire Square, EC2M 4YJ, in pursuit of the company’s business of providing specialist products and consultancy services designed to help our clients digitally connect and transform their businesses.</Paragraph>

                </LineDotContainer>

            </div>
            <Footer/>
        </MainLayout>
    )
}